<template>
  <div class="group-lessons-list">
    <div class="group-lessons-table">
      <vs-table
        multiple
        v-model="selected"
        :data="lessons"
        :hoverFlat="true"
        notSpacer
      >
        <template slot="header">
          Lessons ({{ lessons.length }})
          <span v-if="selected.length && $can('manage', 'lesson')">
            <span v-if="savedCount">Saved: {{ savedCount }}</span>
            <vs-button
              type="flat"
              color="primary"
              @click="batchTeacherPopypActive = true"
              >Set teachers</vs-button
            >
            <vs-button type="flat" color="danger" @click="batchCancel"
              >Cancel selected</vs-button
            >
          </span>
        </template>
        <template slot="thead">
          <vs-th> </vs-th>
          <vs-th> Lesson name </vs-th>
          <vs-th> Lesson teachers </vs-th>
          <vs-th> Assistants </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="{ crossed: tr.cancelled }"
          >
            <vs-td>
              <vs-icon
                icon="done"
                color="success"
                v-if="tr.is_lesson_ended"
              ></vs-icon>
              <vs-icon
                icon="fiber_manual_record"
                color="primary"
                v-if="tr.is_lesson_started && !tr.is_lesson_ended"
              ></vs-icon>
            </vs-td>
            <vs-td :data="data[indextr].name">
              <router-link
                :to="{
                  name: 'Lessons',
                  params: { lesson_id: data[indextr].id },
                }"
                class="lesson-link"
              >
                {{ data[indextr].name }}
              </router-link>
              <div>
                {{
                  $moment(data[indextr].lesson_time * 1000).format(
                    'DD MMMM YYYY HH:mm'
                  )
                }}
              </div>
            </vs-td>
            <vs-td :data="data[indextr].teacher_id">
              <div class="lesson-teacher">
                <vs-chip transparent>
                  <vs-avatar
                    :src="
                      tr.main_teacher.options && tr.main_teacher.options.avatar
                        ? `${proxy}/static/files/${tr.main_teacher.options.avatar}`
                        : undefined
                    "
                  ></vs-avatar>
                  {{ tr.main_teacher.nickname }}
                  <span
                    v-if="
                      tr.main_teacher.date_start && !tr.main_teacher.date_end
                    "
                    class="lesson-in-work"
                  >
                    In work
                  </span>
                  <span v-if="tr.main_teacher.date_end" class="lesson-in-done">
                    Done
                  </span>
                  <span
                    v-if="tr.is_main_teacher_replaced"
                    class="is-main-teacher-replaced markdown small warning"
                  >
                    Replacement
                  </span>
                </vs-chip>
              </div>
              <div
                v-for="(t, key) of data[indextr].other_teachers"
                :key="key"
                class="lesson-teacher"
              >
                <vs-chip transparent>
                  <vs-avatar
                    :src="
                      t.options && t.options.avatar
                        ? `${proxy}/static/files/${t.options.avatar}`
                        : undefined
                    "
                  ></vs-avatar>
                  {{ t.nickname }}
                  <span
                    v-if="t.date_start && !t.date_end"
                    class="lesson-in-work"
                  >
                    In work
                  </span>
                  <span v-if="t.date_end" class="lesson-in-done"> Done </span>
                </vs-chip>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].assistants">
              <div
                v-for="(t, key) of data[indextr].assistants"
                :key="key"
                class="lesson-teacher"
              >
                <vs-chip transparent>
                  <vs-avatar
                    :src="
                      t.options && t.options.avatar
                        ? `${proxy}/static/files/${t.options.avatar}`
                        : undefined
                    "
                  ></vs-avatar>
                  {{ t.nickname }}
                  <span v-if="t.visited_at" class="lesson-in-done"> Done </span>
                </vs-chip>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="popup-edit" v-if="batchTeacherPopypActive">
      <vs-popup
        class="form-popup edit-teachers__popup"
        classContent="edit-teachers__popup-content"
        title="Batch edit teachers"
        :active.sync="batchTeacherPopypActive"
        v-if="batchTeacherPopypActive"
      >
        <batch-edit-teachers @save="batchTeacherSaveHandler" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import batchEditTeachers from './batchEditTeachers.vue'
export default {
  components: { batchEditTeachers },
  data() {
    return {
      selected: [],
      proxy: process.env.VUE_APP_PROXY,
      batchTeacherPopypActive: false,
      savedCount: 0,
    }
  },
  computed: {
    ...mapState({
      group: state => state.groups.singleGroup,
      lessons: state => state.lessons.lessons,
    }),
  },
  methods: {
    batchTeacherSaveHandler: async function({ otherTeachers, assistants }) {
      this.savedCount = 0
      await this.selected.forEach(async lesson => {
        const { id, lesson_time, students } = lesson
        const data = {
          id,
          lesson_time,
          students,
          assistants,
          other_teachers: otherTeachers,
        }
        await this.$store
          .dispatch('lessons/setLessonsAction', { data })
          .then(() => this.savedCount++)
          .catch(e => {
            this.$vs.notify({
              title: 'Lessons not saved',
              text: `${e}`,
              color: 'danger',
              position: 'top-right',
            })
          })
      })
      this.$vs.notify({
        title: 'Lessons saved',
        text: `All lessons send to changes`,
        color: 'success',
        position: 'top-right',
      })
      this.savedCount = 0
      this.batchTeacherPopypActive = false
    },
    batchCancel: async function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Cancel`,
        text: 'Are you sure you want to cancel selected lessons?',
        accept: () => {
          this.selected.forEach(async lesson => {
            const { id } = lesson
            await this.$store
              .dispatch('lessons/cancelLessonAction', id)
              .then(() => {
                this.savedCount++
                this.$emit('updateLessons')
              })
          })
        },
      })
    },
  },
}
</script>

<style lang="scss">
.group-lessons-list {
  margin-top: 15px;
  .group-lessons-table {
    .crossed {
      text-decoration: line-through;
      td {
        background-color: var(--var-cancelled);
      }
    }
    header {
      position: sticky;
      // top: 7.5rem;
      z-index: 100;
    }
  }
  .lesson-teacher {
    .con-vs-chip {
      float: none;
      display: inline-block;
    }
  }
}
.edit-teachers__popup {
  .vs-popup {
    .edit-teachers__popup-content {
      height: 100%;
      overflow: visible;
    }
  }
}
</style>
